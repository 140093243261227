<style scoped>
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~vue-toast-notification/dist/theme-sugar.css";
@import "~pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
</style>
<template id="main-app">
  <div class="page-wrapper chiller-theme toggled">
    <a
      id="show-sidebar"
      class="btn btn-sm btn-light text-left"
      href="javascript:void(0)"
      style="border-radius: 0px; color: brown"
    >
      <i class="fas fa-bars"></i>
    </a>
    <nav id="sidebar" class="sidebar-wrapper">
      <div class="sidebar-content">
        <div class="sidebar-brand">
          <a href="#">
            <img
              class="img-responsive"
              src="../../public/assets/img/Logo-Tsaravidy.jpg"
              alt="Logo"
              style="max-width: 100%; height: auto"
            />
            <!-- :src="logo_soc==''?'../public/assets/img/Logo-Tsaravidy.jpg':BASE_URL+'/public/assets/img/'+logo_soc" -->
          </a>
          <div id="close-sidebar">
            <i class="fas fa-bars"></i>
          </div>
        </div>
        <div class="sidebar-header" style="margin-top: 70px">
          <div class="user-pic">
            <img
              class="img-responsive img-rounded"
              src="../../public/assets/img/user.jpg"
              alt="User picture"
            />
          </div>
          <div class="user-info">
            <span class="user-name">
              <strong>{{ nom_user }}</strong>
            </span>
            <span class="user-role">{{ role_user }}</span>
            <span class="user-status" style="cursor: pointer;">
              <i class="fa fa-caret-right"></i>
              <span
                ><a @click="doLogout" style="color: #888; cursor: pointer;">
                  Se deconnecter</a
                ></span
              >
            </span>
          </div>
        </div>

        <div class="sidebar-menu">
          <ul>
            <li class="header-menu">
              <span>Clients</span>
            </li>
            <li>
              <router-link to="/clientreglements">
                <i class="fa fa-users"></i>
                <span>Documents de vente</span>
              </router-link>
            </li>
            <li>
              <router-link to="/clientarticles">
                <i class="fas fa-list"></i>
                <span>Liste des articles</span>
              </router-link>
            </li>
          </ul>
        </div>
        <!-- sidebar-menu  -->
      </div>
      <!-- sidebar-content  -->
    </nav>

    <!-- sidebar-wrapper  -->
    <main class="page-content">
      <div class="all-content">
        <header>
          <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <a class="navbar-brand" href="#">
              <i class="fas fa-store-alt"></i>
              MAGASIN <i class="fas fa-angle-right"></i>
              <!--strong> Tsaravidy Morafeno</strong> &nbsp;<i
                class="fas fa-angle-right"
              ></i-->
              <span> {{ titre }}</span>
            </a>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav mr-auto"></ul>
              <ul class="navbar-nav">
                <li class="nav-item dropdown">
                  <a
                    class="btn btn-light dropdown-toggle color-newrest-gris"
                    title="Langues"
                    type="button"
                    data-toggle="dropdown"
                    href="javascript:void(0);"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fas fa-globe fa-2x"></i>
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby=""
                  >
                    <a class="dropdown-item" href="javascript:void(0)" @click="switchLanguage('fr')" :class="{'bg-secondary text-white': $i18n.locale=='fr'}"
                      ><i class="fas fa-angle-right"></i> Français</a
                    >
                    <a class="dropdown-item" href="javascript:void(0)" @click="switchLanguage('en')" :class="{'bg-secondary text-white': $i18n.locale=='en'}"
                      ><i class="fas fa-angle-right"></i> English</a
                    >
                    
                  </div>
                </li>
                <li class="nav-item dropdown">
                  <a
                    class="btn btn-light dropdown-toggle color-newrest-gris"
                    title="Paramètres utilisateurs"
                    type="button"
                    data-toggle="dropdown"
                    href="javascript:void(0);"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fas fa-users-cog fa-2x"></i>
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby=""
                  >
                    <a class="dropdown-item"  @click="doLogout"
                      ><i class="fas fa-sign-out-alt"></i> Se déconnecter</a
                    >
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </header>

        <div class="container-fluid">
          <router-view @change-page="onChangePage" />
        </div>
        <div v-if="showLoading" class="dna-loader">
          <img :src="base_url+'/public/assets/img/loading.gif'" />
        </div>
        
      </div>
    </main>
    <!-- page-content" -->
  </div>
</template>
  
<script>
import axios from "axios";

export default {
  props: {
    users_info: { 
      type: Object,
      default: () => {},
      required: false
    },
    
  },
  data: function () {

    return {
      titre: "",
      nom_user: "",
      showLoading: false,
      role_user: "CLIENT",
      id_user: "",
      logo_soc: "",
      nom_soc: "",
      base_url: this.BASE_URL,
    };
  },
  methods: {
    onChangePage: function (pageTitle) {
      this.titre = pageTitle;
      console.log(this.titre);
    },
    onChangeLoad: function (status, userData) {
      // console.log("LOADING");
      if (status == "loading") {
        this.showLoading = true;
        console.log("LOADING");
      } else {
        this.showLoading = false;
        console.log("LOADED");
      }
      this.nom_user = userData.clients_gc_nom;
      this.role_user = userData.clients_gc_role;
      this.logo_soc = userData.users_gest_com_logo;
      this.id_user = userData.clients_gc_id;
      
    },
    switchLanguage: function (lang) {
      this.$i18n.locale = lang;
      localStorage.current_lang = lang;
    },
    doLogout: function () {
      // var that = this;
      axios.get(this.BASE_URL + "/users/logout").then(function () {
        location.reload();
      });
    },
  },
  created: function () {
    console.log(this.users_info);
    this.nom_user = this.users_info.clients_gc_nom;
    this.$parent.$on("change-load", this.onChangeLoad);
    if (localStorage.current_lang) {
      this.$i18n.locale = localStorage.current_lang;
    }
  },
};
</script>
<style>

</style>